import React, { useState } from "react";
import { useEffect } from "react";
import "./styles.scss";
function PopupUserRegister(props) {
    const [randomIndex, setRandomIndex] = useState(0);
    const [peoplePopup, setPeoplePopup] = useState(props.peoplePopup);
    const [showPopup, setShowPopup] = useState(false);
    const [inited, setInited] = useState(false);
    useEffect(() => {
        if (props.peoplePopup.length > 0) {
            setPeoplePopup(props.peoplePopup);
            const showTimer = setTimeout(() => {
                setInited(true);
                handleOpenPopup();
            }, 4000);
            return () => clearTimeout(showTimer);
        }
    }, [props.peoplePopup]);
    const handleOpenPopup = () => {
        setShowPopup(true);
        setRandomIndex((prev) => (prev === peoplePopup.length - 1 ? 0 : prev + 1));
        const closeTimer = setTimeout(() => {
            handleClosePopup();
        }, 4000);
        // Return a cleanup function to clear the closeTimer if the component unmounts
        return () => clearTimeout(closeTimer);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        const showTimer = setTimeout(() => {
            handleOpenPopup();
        }, 5000);
        // Return a cleanup function to clear the showTimer if the component unmounts
        return () => clearTimeout(showTimer);
    };

    const RenderCard = ({ title, content, pic, show }) => {
        return inited ? (
            <div className={`card ${show ? "show" : "hide"}`}>
                <div className="card-content-wrapper">
                    <img src={pic} alt="logo" className="card-logo" />
                    <div className="card-text">
                        <h6 className="card-title">{title}</h6>
                        <div className="card-content">{content}</div>
                        <div className="card-content">Đã đăng ký thành công</div>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    };
    return (
        <div className="popup-user-register">
            {peoplePopup.length > 0 && (
                <RenderCard
                    title={peoplePopup[randomIndex].name}
                    content={peoplePopup[randomIndex].phone}
                    pic={peoplePopup[randomIndex].pic}
                    show={showPopup}
                />
            )}
        </div>
    );
}

export default PopupUserRegister;