import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CheckIcon from "@mui/icons-material/Check";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./styles.scss";
import {
  mediaInfor,
  arrayTes,
  arrayTrainerCoach,
  catelogyImg,
  arrayTrainerInfo,
  arrayService,
  peoplePopup,
} from "./const";
import GamesIcon from "@mui/icons-material/Games";
import { Box, Modal } from "@mui/material";
import RegisterForm from "share/registerForm";
import bblBanner from "assets/images/home/bbl-banner.jpg";
import bgForm from "assets/images/home/bg-form.jpg";
import Trainer from "assets/images/home/trainer.jpg";
import { Link, useNavigate } from "react-router-dom";
import WrapperImg from "share/wrapperImg";
import FormCountDownt from "app/components/formCountDown";
import PopupUserRegister from "app/components/popupUserRegister";
gsap.registerPlugin(ScrollTrigger);
function ClientModulesHome() {
  const [type, setType] = useState("lrworkshop");
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const storedParams = localStorage.getItem("utm");
  const parsedParams = JSON.parse(storedParams);
  const [openModal, setOpenModal] = useState(true);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const stickyElement = document.querySelector(".bbl-header");
    const stickyOffset = stickyElement.offsetTop;
    const handleScroll = () => {
      if (window.scrollY >= stickyOffset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleYouTubeClick = () => {
    window.open(
      "https://www.youtube.com/results?search_query=la+h%E1%BA%A1+giang+thanh",
      "_blank"
    );
  };
  const handleSpotifyClick = () => {
    window.open(
      "https://podcasters.spotify.com/pod/show/podcastchamchua",
      "_blank"
    );
  };

  const renderPopUp = () => {
    const style = {
      top: "15%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
      // boxShadow: 24,
      position: "absolute",
      display: "block",
      width: isMobile ? "90%" : "50%",
    };
    return (
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setStep(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "40px",
        }}
      >
        <Box sx={style}>
          <div
            className="container "
            style={{ overflow: "auto", maxHeight: "60vh" }}
          >
            <div className="position-absolute" style={{ zIndex: 1 }}>
              {renderSwitch()}
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  const renderSwitch = () => {
    switch (step) {
      case 0:
        return (
          <RegisterForm
            title={"Đăng ký nhận ưu đãi"}
            type={type}
            setStep={(i) => {
              setStep(i);
            }}
            setData={(i) => {
              setData(i);
            }}
            parsedParams={parsedParams}
          />
        );
      case 1:
        return (
          <div className="row popup">
            <div className="popup-background"></div>
            <div className="overlay" />
            <div className="col-12 position-relative">
              <div className="px-5 py-5">
                <div className="row">
                  <h2>CÁM ƠN BẠN </h2>
                  <h4 style={{ textAlign: "center" }}>
                    ĐÃ ĐĂNG KÝ CHƯƠNG TRÌNH
                  </h4>
                </div>
                <div className="row mt-4 text-center">
                  <p> Chúng tôi sẽ sớm liên hệ lại với bạn</p>
                  <p>Liên hệ hotline để được giải đáp thắc mắc</p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  const renderHeader = () => {
    const CONTENT_MENU = [
      {
        title: "Thông Tin Chuyên Gia",
        url: "#bbl-trainer",
      },

      {
        title: "Chia Sẻ",
        url: "#bbl-testimonial",
      },
      {
        title: "Dịch Vụ",
        url: "#bbl-service",
      },
      {
        title: "Tin Tức",
        url: "#bbl-media-infor",
      },
    ];
    const renderDropdownChildren = (data) => {
      return (
        <ul>
          {data?.map((child, index) => {
            return (
              <li key={index}>
                <Link to={child.url}>{child?.title?.toLocaleUpperCase()}</Link>
                {child.childMenu && renderDropdownChildren(child.childMenu)}
              </li>
            );
          })}
        </ul>
      );
    };
    return (
      <div className={`bbl-header  ${isSticky ? "fixed" : ""}`}>
        <div className="bbl-header-menu">
          <div className="container">
            <ul className="header-dropdown ">
              {CONTENT_MENU?.map((ele, index) => {
                return (
                  <li key={index}>
                    <a href={ele.url}>{ele.title.toLocaleUpperCase()}</a>

                    {ele.childMenu && renderDropdownChildren(ele.childMenu)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <PopupUserRegister peoplePopup={peoplePopup} />
      </div>
    );
  };

  const renderTrainer = () => {
    return (
      <div className="mt-5 bbl-trainer" id="bbl-trainer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <img src={Trainer} alt="Trainer" className="img-trainer" />
            </div>
            <div className="col-md-6 mb-3" style={{ display: "grid" }}>
              <div className="bbl-trainer-info mb-3">
                <div className="headline-h4">
                  Chuyên Gia Huấn Luyện <br />
                  NLP Master Trainer <br />
                  LA HẠ GIANG THANH
                </div>
              </div>
              <div className="d-flex align-self-stretch">
                <div className="">
                  {arrayTrainerInfo.map((ele, index) => {
                    return (
                      <div key={index} className="mb-2">
                        {ele}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderTrainerCoach = () => {
    return (
      <div className="mt-5 bbl-trainer-coach">
        <div className="container">
          <div className="headline-h4">
            CÁC LĨNH VỰC CHUYÊN GIA <br /> TƯ VẤN
          </div>
          <div className="row">
            {arrayTrainerCoach.map((item, index) => {
              return (
                <div className="col-md-4 bbl-trainer-coach-card" key={index}>
                  <div>
                    <img src={item.src} className="bbl-trainer-coach-img" />
                    <p className="bbl-trainer-coach-text">{item.caption}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const renderTestimonial = () => {
    return (
      <div className="bbl-testimonial" id="bbl-testimonial">
        <div className="container">
          <div className="headline-h1">CHIA SẺ HỌC VIÊN</div>
          <div className="row">
            {arrayTes.map((tes, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <div className="bbl-testimonial-cards">
                    <img src={tes.img} alt="customer-avt" />
                    <div className="bbl-testimonial-content">
                      <p className="bbl-testimonial-name">{tes.name}</p>
                      <p>{tes.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="icon-container">
            <div className="icon-wrapper">
              <i
                className="fab fa-youtube icon icon-yt"
                onClick={handleYouTubeClick}
                title="Go to YouTube"
              ></i>
              <div className="icon-description">Xem thêm trên Youtube</div>
            </div>
            <div className="icon-wrapper">
              <i
                className="fab fa-spotify icon icon-spot"
                onClick={handleSpotifyClick}
                title="Go to Spotify"
              ></i>
              <div className="icon-description">Nghe thêm trên Spotify</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderService = () => {
    return (
      <div className="bbl-service" id="bbl-service">
        <div className="container">
          <div className="headline-h1 mb-5 mt-5">
            CÁC DỊCH VỤ CỦA <br />
            LEONARDO ROSSI
          </div>
          <div className="row bbl-service-cards-container">
            {arrayService.map((ser, index) => {
              return (
                <div
                  className="col-md-6 col-12  mb-3 child-full-height"
                  key={index}
                >
                  <div className="bbl-service-cards">
                    <img src={ser.img} alt="customer-avt" />
                    <div className="bbl-service-content">
                      <div className="bbl-service-title headline-h3">
                        {ser.title}
                      </div>
                      {ser.priceDefault && (
                        <div className="box-price mb-3">
                          <span className="price-default">
                            {ser.priceDefault}
                          </span>
                          <span className="price-promo">{ser.pricePromo} </span>
                        </div>
                      )}
                      {ser.type === "lrworkshop" && (
                        <div className="bbl-service-workshop">
                          <div className="headline-h4">GENERAL</div>
                          <div className="box-price mb-3">
                            <span className="price-default">199.000</span>
                            <span className="price-promo">99.000</span>
                          </div>
                          <b>
                            <CheckIcon
                              key={index}
                              sx={{ color: "blue", marginRight: "10px" }}
                            />
                            Tặng Nước Detox Thanh Lọc Cơ Thể
                          </b>
                          <div className="headline-h4">VIP</div>
                          <div className="box-price mb-3">
                            <span className="price-default">6.999.000</span>
                            <span className="price-promo">3.600.000</span>
                          </div>
                          <ul>
                            <li>
                              <CheckIcon
                                key={index}
                                sx={{ color: "blue", marginRight: "10px" }}
                              />
                              Cắm hoa thư giản chữa lành Thưởng trà thanh lọc cơ
                              thể
                            </li>
                            <li>
                              <CheckIcon
                                key={index}
                                sx={{ color: "blue", marginRight: "10px" }}
                              />
                              Khai vấn 1:1 cùng chuyên gia
                            </li>
                            <li>
                              <CheckIcon
                                key={index}
                                sx={{ color: "blue", marginRight: "10px" }}
                              />
                              Tìm hiểu kiến thức chế độ dinh dưỡng bảo vệ sức
                              khỏe thông qua hoạt động ăn trưa cùng chuyên gia
                            </li>
                          </ul>
                        </div>
                      )}
                      <ul>
                        {ser.arrayContent?.map((item, index) => (
                          <li key={index}>
                            <CheckIcon
                              key={index}
                              sx={{ color: "blue", marginRight: "10px" }}
                            />
                            {item}
                          </li>
                        ))}
                      </ul>
                      {ser.callToAction && (
                        <div className="bbl-service-call-to-action animate-bounce">
                          {ser.callToAction}
                        </div>
                      )}
                      {ser.promo && <img src={ser.promo} alt="voucher" />}
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={() => {
                          setOpenModal(true);
                          setType(ser.type);
                        }}
                        className="btn register-button"
                      >
                        TƯ VẤN NGAY
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderMediaInfor = () => {
    const renderMediaHeader = (title) => {
      return (
        <div className="headline-h3 mb-3">
          <GamesIcon /> {title}
        </div>
      );
    };
    return (
      <div className="bbl-media-infor mt-5  mb-5" id="bbl-media-infor">
        <div className="container">
          <div className="headline-h1">{mediaInfor.title}</div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="bbl-media-infor-podcast mb-5">
                <img
                  src={mediaInfor.podcast.img}
                  alt="Pod cast"
                  className="mb-3"
                />
                <div className="d-flex mb-3">
                  <CheckIcon sx={{ color: "blue", marginRight: "10px" }} />
                  {mediaInfor.podcast.des}
                </div>
                <div className="d-flex justify-content-center">
                  <Link
                    to={mediaInfor.podcast.url}
                    className="btn btn-podcast"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PlayArrowIcon /> {mediaInfor.podcast.callToAction}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5 ">
              <div className="bbl-media-infor-avt">
                <img src={mediaInfor.avt} alt="Coach Thanh" />
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="bbl-media-infor-news">
                {renderMediaHeader(mediaInfor.new?.title)}
                <b>Báo VnExpress</b>
                <div className="row mt-3">
                  {mediaInfor.new?.vnExpress.map((item, index) => {
                    return (
                      <div className="col-6 mb-3" key={index}>
                        <Link
                          to={item}
                          className="btn btn-news "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PlayArrowIcon /> {mediaInfor.new.callToAction}
                        </Link>
                      </div>
                    );
                  })}
                  <b className="mb-3">Báo Thanh Niên</b>
                  {mediaInfor.new?.youth.map((item, index) => {
                    return (
                      <div className="col-6 mb-3" key={index}>
                        <Link
                          to={item}
                          className="btn btn-news "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PlayArrowIcon /> {mediaInfor.new.callToAction}
                        </Link>
                      </div>
                    );
                  })}
                  <b className="mb-3">Báo Eva</b>
                  {mediaInfor.new?.eva.map((item, index) => {
                    return (
                      <div className="col-6 mb-3" key={index}>
                        <Link
                          to={item}
                          className="btn btn-news "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PlayArrowIcon /> {mediaInfor.new.callToAction}
                        </Link>
                      </div>
                    );
                  })}
                  <b className="mb-3">Báo Nữ Doanh Nhân</b>
                  {mediaInfor.new?.nuDoanhNhan.map((item, index) => {
                    return (
                      <div className="col-6 mb-3" key={index}>
                        <Link
                          to={item}
                          className="btn btn-news "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PlayArrowIcon /> {mediaInfor.new.callToAction}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderForm = () => {
    return (
      <div className="bbl-form">
        <div
          className="parallax-banner section-background"
          style={{
            backgroundImage: "url(" + bgForm + ")",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                "Đây là cơ hội để bạn dành thời gian cho chính mình, chăm sóc và
                yêu thương mình nhiều hơn"
              </h1>
            </div>
            <div className="col-md-6">
              <RegisterForm
                title={"Đăng ký nhận ưu đãi"}
                type={type}
                setStep={(i) => {
                  setStep(i);
                }}
                setData={(i) => {
                  setData(i);
                }}
                parsedParams={parsedParams}
              />
              <FormCountDownt date={"09/30/2024"} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  // Màu 1 #a18cd1 - màu 2 #fbc2eb
  // Màu nút tư vấn màu 1 rgb(203, 108, 230) màu 2 #ff89fe màu gradient
  return (
    <div className="position-relative bbl">
      <div className="bbl-banner">
        <div
          className="section-background"
          style={{
            backgroundImage: "url(" + bblBanner + ")",
          }}
        ></div>
      </div>
      {renderHeader()}
      <div className="container mt-4">
        <div className="header-title">
          <div className="headline-h3">
            Khai Phá Vẻ Đẹp Tiềm Ẩn Và Giá Trị Thực Sự Của Bản Thân Bạn Cùng
            Chuyên Gia Tâm Lý Quốc Tế La Hạ Giang Thanh
          </div>
        </div>
        <h5 className="mt-3  header-description">
          Mỗi đóa hoa đều có vẻ đẹp riêng, vẻ đẹp của mỗi người phụ nữ cũng như
          thế,không có bông hoa nào không đẹp, không có người phụ nữ nào kém sắc
        </h5>
      </div>
      <WrapperImg catelogyImg={catelogyImg} />
      {renderTrainer()}
      {renderTrainerCoach()}
      {renderTestimonial()}
      {renderService()}
      {renderMediaInfor()}
      {renderForm()}
      {renderPopUp()}
    </div>
  );
}

export default ClientModulesHome;
